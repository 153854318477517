<template>
  <ProductsForm
    :composed="isComposed"
    :product="product"
    :type="'create'"
    @save="createProduct"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    ProductsForm: () => import('./ProductsForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    isComposed: null,
    isFormValid: true,
    askToLeave: true,
    product: {
      product_name: null,
      product_reference: null,
      product_ncm: null,
      product_description: null,
      product_height: null,
      product_width: null,
      product_diameter: null,
      product_weight: null,
      product_depth: null,
      product_energy: null,
      product_keywords: null,
      product_vendors: [],
      vendor_selected: null,
      categories: [],
      segments: [],
      special_dates: [],
      product_print_height: null,
      product_print_width: null,
      product_print_measure: null,
      print_ids: [],
      product_colors: [],
      product_images: [],
      product_videos: [],
      product_composition: {
        items: [],
        total_cost: 0,
      },
      is_new: false,
      is_on_sale: false,
      is_clearance_sale: false,
      is_temporary: false,
    },
  }),
  created() {
    this.isComposed = this.$route.params.isComposed
    this.product.isComposition = this.$route.params.isComposed
  },
  methods: {
    // validate(value) {
    //   this.isFormValid = value;
    // },

    createProduct() {
      this.askToLeave = false

      const formData = new FormData() // Criando um FormData
      const productKeys = Object.keys(this.product) // Chaves do objeto de produto
      const productValues = Object.values(this.product) // Valores do objeto de produto

      /*
       ** Instanciando o array de imagens do produto
       */
      var productImages = this.product.product_images
      var images_string = []
      for (let i = 0; i < productImages.length; i++) {
        /*
         ** Criando um atributo chamado "color_id" dentro do File
         ** recebido pelo parâmetro "src" do PRODUTO
         */
        let str = {
          color_id: productImages[i].color_id,
          name_file: productImages[i].src.name,
        }

        // let nameFile = "colorID"+ product[i].color_id + "_" + product[i].src.name
        let nameFile = productImages[i].src.name
        let newFile = new File([productImages[i].src], nameFile)
        // let newFile = new File([product[i].src], product[i].src.name)
        newFile.color_id = productImages[i].color_id
        /*
         ** Definindo os índices do array "product_images" como File
         */
        productImages[i] = newFile
        /*
         ** Fazendo append do novo array para o form data
         */ formData.append('product_images[]', newFile)
        images_string.push(str)
      }

      // Processamento dos vídeos do produto
      var videos = this.product.product_videos
      var videos_string = []
      for (let i = 0; i < videos.length; i++) {
        let nameFile =
          videos[i].id !== undefined ? videos[i].src : videos[i].name
        let str = {
          id: videos[i].id ?? null,
          name_file: nameFile,
        }
        let newFile = new File([videos[i]], nameFile)
        videos[i] = newFile
        formData.append('product_videos[]', newFile)
        videos_string.push(str)
      }
      formData.append('image_string[]', JSON.stringify(images_string))
      formData.append('video_string[]', JSON.stringify(videos_string))

      // NOVO: Envia o relacionamento de prints usando print_ids.
      // Suponha que o seu objeto de produto possua a propriedade "prints" (array de prints com pelo menos o campo "id")
      const printIds =
        this.product.prints && this.product.prints.length > 0
          ? this.product.prints.map((item) => item.id)
          : []
      formData.append('print_ids', JSON.stringify(printIds))

      // Fazendo append dos demais campos, ignorando o campo antigo "product_print_types"
      for (let i = 0; i < productKeys.length; i++) {
        if (
          productValues[i] !== null &&
          productKeys[i] !== 'product_images' &&
          productKeys[i] !== 'product_videos' &&
          productKeys[i] !== 'print_ids'
        ) {
          // Se o campo for objeto (por exemplo, composição ou vendors), envia como JSON
          if (
            [
              
              'product_composition',
              'vendor_selected',
              'product_vendors',
            ].includes(productKeys[i])
          ) {
            formData.append(productKeys[i], JSON.stringify(productValues[i]))
          } else {
            formData.append(productKeys[i], productValues[i])
          }
        }
      }

      // Envia as categorias combinadas
      let categories = []
      categories.push(
        ...this.product.categories,
        ...this.product.segments,
        ...this.product.special_dates
      )
      formData.append('all_product_categories', JSON.stringify(categories))

      // Envio da requisição
      if (this.isFormValid) {
        this.$api
          .post('products', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Produto cadastrado com sucesso',
              confirm: () => {
                this.$router.push({ name: 'products' })
              },
            })
          })
          .catch((error) => {
            let message =
              error?.response?.data?.message ||
              'Ops! Falha ao cadastrar o produto'
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: message,
              caption: 'Verifique se os campos estão preenchidos corretamente.',
              confirm: () => {
                this.$store.commit('setModal', { show: false })
              },
            })
          })
      } else {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar cadastrar este produto',
          caption: 'Verifique se os campos estão preenchidos corretamente.',
          confirm: () => {
            this.$store.commit('setModal', { show: false })
          },
        })
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
